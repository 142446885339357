
.backGround {
  width: 100%;
  background-color: black;
  top: 0;
  position: relative;
  height: fit-content;
  color: white;
  margin-left: 0;
}
.yellowLine {
  background-color: #FFD700;
  height: 5px;
  width: 100%;
}

.banner {
  margin-top: 0px;
  background-image: url('../../images/bg.png');
  /* background-position: top center; */
  background-size: cover;
  background-repeat: no-repeat;
  height: 92vh;  
  width: 100%; 
  }
 
.container {
  display: flex;
}

  .imgBox {
    display: flex;
    height: 200px;
    width: 200px;
    transition: 0.5s;
    border: none; 
  }
  @media (max-width: 1080px) {
    .imgBox {
      height: 150px;
      width: 150px;
      margin-left: 50px;
    }
  }
  @media (max-width: 720px) {
    .imgBox {
      height: 100px;
      width: 100px;
    }
  }
  .imgBox:hover {
    transform: scale(1.1);
  }
  .googlePlayBox {
    transition: 0.5s;
  }
  .googlePlayBox img{
    position: absolute;
    height: 150px;
    width: 350px;
    margin-left: 200px;
  }
  @media (max-width: 1080px) {
    .googlePlayBox img{
      margin-left: auto;
      margin-right: auto;
      height: 120px;
      width: 300px;
    }
  }  
  .googlePlayBox:hover {
    transform: scale(1.1);
  }
  .leftSide {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    gap: 2rem;
    position: absolute;
    margin-top: 35vh;
  }
  @media (max-width: 1080px) {
    .leftSide {
      grid-template-columns: 2fr 2fr;
      margin-top: 250px;
      margin-left: auto;

    }
  }  
  @media (max-width: 720px) {
    .leftSide {
      margin-top: 200px;

    }
  } 
  .rightSide {
    position: absolute;
    margin-left: 40%;
    margin-top: 35vh;
  }
  
  @media (max-width: 1080px) {
    .rightSide{
      margin-left: 0;
      margin-top: 650px;
      margin-left: 50px;

    }
  }
  @media (max-width: 720px) {
    .rightSide{
      margin-left: 30px;
      margin-top: 500px;
    }
  }
  .text {
    color: aliceblue;
    font-weight: 700;
    font-size: 35px;
    margin-left: 200px;
    white-space: nowrap;
  }
  @media (max-width: 1080px) {
    .text{
      margin-left: auto;
      margin-right: auto;
      font-size: 30px;
    }
  }  